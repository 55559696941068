/* mobile view */
@media only screen and (max-width: 350px) {
  .logo {
    width: 45px;
  }

  .domande_box {
    gap: 2px;
  }

  .download_app {
    text-align: center;
    padding: 0 20px;
  }

  .f_sec_3 {
    font-size: 14px;
  }

  .exc_item input {
    max-width: 60px !important;
  }

  .NcPat_box {
    flex-direction: column;
  }

  .NcPat_box a {
    width: 100%;
  }

  .nc_item {
    /* width: 100%; */
    flex-direction: column;
    gap: 20px;
  }

  .nc_text {
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .clk_box {
    flex-direction: column-reverse;
    gap: 10px;
  }

  .qTopSec {
    grid-template-columns: repeat(3, 1fr);
  }

  .qTopSec :nth-child(3),
  .qTopSec :nth-child(4) {
    display: none;
  }
  .tour_img_container {
    height: 150px !important;
  }
}

@media only screen and (max-width: 550px) {
  .s_sec{
    width: 100% !important;
  }
}

@media only screen and (max-width: 495px) {

  .chat_info h2{
    font-size: 15px !important;
  }
  .chat_info p{
    font-size: 12px !important;
  }

  .user_name{
    display: none !important;
  }

  .article_box,
  .main_container {
    grid-template-columns: 1fr !important;
  }

  .tsc_item{
    width: 100%!important;
    box-sizing: border-box;
  }

  .tsc_item img{
    height: 180px !important;
  }

  .tcs_box, .service_box{
    grid-row-gap: 20px !important;
  }

  .tour_item{
    flex-direction: column;
    margin: 30px ;
  }
  .tour_item img{
    max-width: 100%;
  }

  .question {
    display: block;
  }

  .seaker_icon {
    margin-bottom: 0;
  }

  .question p {
    padding-top: 0;
  }

  .Copyright {
    padding: 15px 20px;
  }

  .auth ul li a {
    font-size: 14px;
  }

  .f_sec_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login_container {
    padding: 20px;
  }

  .d_sec {
    grid-template-columns: 1fr;
  }

  .f_sec_1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login_container {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
  }

  .exc_item input {
    max-width: 100px;
  }

  .course_days,
  .Quiz_item {
    grid-template-columns: 1fr;
  }

  .c_text,
  .free_line {
    font-size: 16px;
  }

  .d_sec {
    grid-template-columns: 1fr;
  }

  .tcs_box {
    display: block;
    margin: 15px 0;
  }

  .ed_item {
    flex-direction: column;
  }

  .ed_item div {
    gap: 10px;
    align-items: center;
  }

  .ed_item img {
    width: 100%;
    height: 250px;
  }

  .signup_input,
  .ticket_input,
  .add_input {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .course_demo_info p {
    font-size: 16px;
  }

  .service_box,
  .embassy {
    display: flex;
    flex-direction: column;
  }

  .basic_info ul li {
    display: flex;
  }

  .basic_details {
    flex-direction: column;
  }

  .status span {
    padding: 0 10px;
  }

  .table th,
  .table td {
    padding: 0.2rem;
  }

  .table {
    font-size: 12px;
  }

  .taken_services {
    padding: 0;
  }

  .title_user h3 {
    font-size: 17px;
  }

  .quiz_serial {
    grid-template-columns: repeat(10, 1fr) !important;
  }

  .page_serial {
    grid-template-columns: repeat(5, 1fr);
  }

  .radio_input {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }

  .radio_input div {
    justify-content: center;
    margin: 10px 0;
  }

  .click_icons {
    font-size: 1rem;

  }
}

@media only screen and (max-width: 720px) {
  .uper_scheda {
    flex-direction: column-reverse;
  }

  .Scheda_box {
    display: none;
  }

  .click_box {
    height: 100%;
  }

  .sb_mobile {
    display: block;
    margin-top: 20px !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 780px) {
  .signup_container {
    width: 100%;
  }

  .extra_text {
    display: none;
  }

  .exam_board {
    width: auto !important;
  }

  .exam_board img,
  .qT_img img {
    max-width: 50px;
    box-sizing: border-box;
  }

  .q_left_text :first-child {
    font-weight: 400 !important;
    width: 100px;
    display: block;
  }

  .signup_main {
    padding: 0 20px;
  }

  .p_main_sec {
    display: block;
  }

  .profile_sec {
    margin-bottom: 15px;
  }

  .down_btn {
    display: flex !important;
  }

  .blog_box,
  .article_box {
    grid-template-columns: 1fr 1fr;
  }

  .sign_out_up {
    display: none !important;
  }

  .visa_ticket_travel div img {
    height: 81%;
  }
}

@media only screen and (max-width: 660px) {

  .notif_drop{
    right: -20px !important;
  }

  .services,
  .money_rate {
    margin: 20px auto;
  }

  .download_app {
    flex-direction: column;
    gap: 0;
    padding: 0 10px;
  }

  .download_app a {
    margin-bottom: 14px;
  }

  .visa_ticket_travel {
    flex-direction: column;
  }

  .other_service {
    flex-direction: column;
  }


  .req_container {
    padding: 20px;
  }

  .req_subox {
    padding: 0;
  }

  .c_text,
  .free_line {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .basic_info ul li {
    gap: 10px;
  }

  .visa_ticket_travel {
    grid-template-columns: 1fr;
  }

  .visa_ticket_travel div img {
    height: 80%;
  }

  .profile_pic {
    max-width: 500px;
  }
}

@media only screen and (max-width: 820px) {
  .quiz_serial {
    grid-template-columns: repeat(15, 1fr);
  }

  .contect_us {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 960px) {
  .navbar_responsive {
    display: block !important;
  }

  .main_header_res {
    display: block;
  }

  .main_header {
    display: none;
  }

  .header_res_container {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    position: relative;
  }

  .navbar ul {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    position: absolute;
    border-radius: 12px !important;
    background-color: var(--gray-200);
    margin-top: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition-duration: 0.3s;
    z-index: 1;
    top: 60px;
  }

  .navbar ul li a {
    padding: 10px;
    display: inline-block;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    width: 200px;
    border-radius: 12px;
  }

  .nav_res ul {
    transform: scaleY(1);
  }

  .header_logo {
    display: flex;
    align-items: center;
  }


  .f_sec {
    grid-template-columns: 1fr;
  }

  .main_container {
    grid-template-columns: 1fr 1fr;
  }

  .f_sec_4 {
    justify-content: center;
  }

  .download_sug {
    grid-template-columns: 1fr;
    padding: 30px 20px;
  }

  .phone_img {
    display: none;
  }

  .tcs_box {
    grid-template-columns: repeat(3, 1fr);
  }

  .ed_main {
    grid-template-columns: 1fr;
  }

  .blog_header {
    gap: 20px;
  }

  .req_container {
    margin: 30px 20px;
  }
}

@media only screen and (max-width: 820px) {
  .blog_header {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 960px) {
  .navbar_responsive {
    display: none;
  }

  .main_header_res {
    display: none;
  }
}

@media only screen and (max-width: 1040px) {

  .services,
  .money_rate {
    width: 100%;
  }

  .visa {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 20px;
    box-sizing: border-box;
  }
}