:root {
  --primary-color: #4e944f;
  --scecoundry-color: #539b44;
  --mid-green: #4e944f50;
  --primary-white: #ffffff;
  --primary-black: #000000;
  --mat-blue: #28303f;
  --disabl_blue: #28303f50;
  --blue: #82b1f1;
  --dark-blue: #263077;
  --gray-blue: #4e5776;
  --purple-primary: #641cf5;
  --purple-background: #641cf538;
  --sky-blue: #dbeafe;
  --lite-pink: #fee2e2;
  --pink-primary: #fecbcb;
  --pink: #fecfcf65;
  --yellow-lite: #ffedd5;
  --yellow: #ffe176;
  --orange-primary: #ff9a40;
  --orange: #ffb445;
  --orange-background: #ffedd5;
  --red: #dc3545;
  --red-50: #dc354679;
  --border: #d2d5da;
  --gray-200: #f5f5f5;
  --gray-300: rgb(228, 227, 227);
  --gray-400: #9ca3af;
  --gray-500: #9e9e9e;
  --gray-600: #6d7280;
  --gray-700: #4e4e4e;
  --gray-800: #333333;
  --transparent-circle: #ffffff2c;

  --primary-shadow: rgb(0 0 0 / 29%);

  --linear-gradient-orenge: linear-gradient(180deg, #ffb8a1, #ffedd5);
  --linear-gradient-green: linear-gradient(180deg, #8fefb0, #dcfce7);
  --linear-gradient-pink: linear-gradient(180deg, #fc9393, #fed6d6, #fee2e2);
  --linear-gradient-blue: linear-gradient(180deg, #82b1f1, #dbeafe);
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url(./fonts/Poppins-Regular.ttf) format("woff2"),
    url(./fonts/Poppins-Regular.ttf) format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url(./fonts/Poppins-Medium.ttf) format("woff2"),
    url(./fonts/Poppins-Medium.ttf) format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url(./fonts/Poppins-SemiBold.ttf) format("woff2"),
    url(./fonts/Poppins-SemiBold.ttf) format("woff");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url(./fonts/Poppins-Bold.ttf) format("woff2"),
    url(./fonts/Poppins-Bold.ttf) format("woff");
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--mid-green);
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

html {
  scroll-behavior: auto;
}

body {
  font-family: "Poppins", sans-serif;
}

ul {
  padding: 0;
  list-style: none;
}

h3,
h2,
h1 {
  font-weight: 500;
}

a:active {
  color: none;
  /* or specify the desired color */
  text-decoration: none;
  /* optional: remove underline */
}

.active {
  color: var(--primary-color) !important;
}

.active_l {
  background-color: var(--gray-200);
}

.loading_circle {
  width: 30px;
  height: 30px;
  border: 4px solid #e0e0e0;
  border-top-color: var(--primary-color);
  border-bottom-color: var(--primary-color);
  border-radius: 50%;
  animation: loading-animation 1s cubic-bezier(0.34, 0.25, 0.42, 0.99) infinite;
}

.loading_circle_btn {
  width: 15px;
  height: 15px;
  margin: auto;
  border: 4px solid #e0e0e0;
  border-top-color: var(--primary-color);
  border-bottom-color: var(--primary-color);
  border-radius: 50%;
  animation: loading-animation 1s linear infinite;
}

.centerLoading {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.active-l {
  background-color: var(--primary-color);
  color: var(--primary-white) !important;
}

.container {
  max-width: 1160px;
  padding: 0 20px;
  margin: auto;
  position: relative;
}

.main_header,
.basic_details,
.exc_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_header {
  padding: 20px 0px;
}

/* common css */

.user_nav ul li,
.auth,
.navbar ul,
.f_sec_1 ul,
.auth .auth_user {
  display: flex;
  align-items: center;
}

.f_sec_4,
.success_container,
.login_submit,
.signup_submit,
.ticket_submit,
.center_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.d_icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.s_btn img {
  width: 120px;
  border-radius: 6px;
}

.navbar ul li .nav_link {
  text-decoration: none;
  color: var(--mat-blue);
  margin: 0 15px;
  font-size: 16px;
}

.navbar ul li .nav_link:hover{
  color: var(--primary-color) !important;
}

.auth .drop_box li a {
  text-decoration: none;
  color: var(--mat-blue);
  font-size: 16px;
}

.auth ul li .nav_link,
.auth > .log_auth > li > a {
  color: var(--primary-color);
  padding: 10px 15px;
  margin: 0;
  border-radius: 25px;
}

.auth > .log_auth {
  display: flex;
  gap: 10px;
}

.auth > .log_auth > li > a {
  border: 1px solid var(--primary-color);
  text-decoration: none;
}

.user_img {
  width: 37px;
  border-radius: 100%;
}

.user_nav {
  margin: 0;
}

.user_nav .auth_user {
  margin: 0;
  gap: 10px;
  cursor: pointer;
}

.user_name {
  font-size: 14px;
  font-weight: 600;
}

.down_icon {
  width: 24px;
}

ul.nav_head {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0 15px;
}

ul.nav_head > li {
  display: flex;
  align-items: center;
}

.hover_drop {
  position: relative;
}
.hover_drop_more {
  position: relative;
}

li.nav_drop_name {
  cursor: pointer;
}

.drom_down,
.drom_down_more {
  position: absolute;
  z-index: 1;
  width: 155px;
  border-radius: 10px;
  background-color: var(--primary-white);
  box-shadow: 6px 6px 15px var(--primary-shadow);
  transform: scaleY(0);
  transform-origin: top;
  transition-duration: 0.3s;
  right: 0;
}

.hover_drop:hover .drom_down,
.hover_drop_more:hover .drom_down_more {
  transform: scaleY(1);
}

.drom_down ul,
.drom_down_more ul {
  display: block;
  padding: 10px;
  /* top: 104px; */
  width: 155px;
  box-sizing: border-box;
  align-items: baseline;
  flex-direction: column;
}

.down_icon_more {
  width: 16px !important;
}
.drom_down ul li,
.drom_down_more ul li {
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-black);
  gap: 8px;
}

.drom_down_more ul li {
  font-weight: 400 !important;
}

.nav_drop_name {
  font-weight: 400;
}

.drom_down ul li a .drom_down_more ul li a {
  color: var(--primary-black);
  padding: 0;
}

.drom_down ul :last-child {
  justify-content: center !important;
  cursor: pointer;
}

.d_icons {
  width: 20px;
}

.logo {
  max-width: 60px;
  padding: 0 10px;
}

.logo_res {
  max-width: 40px;
}

.download_app {
  background: var(--primary-color);
  display: flex;
  justify-content: center;
  gap: 20px;
  color: white;
  align-items: center;
}

.download_app span {
  padding: 16px 0;
  font-size: 12px;
}

.download_app a {
  display: flex;
}

.download_app a img {
  width: 100px;
  border-radius: 6px;
}

.login_container,
.signup_container {
  border: 1px solid var(--border);
  border-radius: 10px;
  padding: 20px;
  margin: 50px auto;
  box-sizing: border-box;
}

.login_container {
  width: 380px;
}

.signup_container {
  max-width: 760px;
}

.center_logo h3 {
  color: var(--primary-color);
  font-weight: 600;
}

.login_input div label {
  color: var(--gray-800);
}

.input_div input,
.input_div textarea,
.input_div select {
  padding: 10px 15px;
  width: 100%;
  border-radius: 12px;
  border: 2px solid var(--border);
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
  font-family: "Poppins";
  box-sizing: border-box;
}

.phone_input input {
  padding: 23px !important;
  padding-left: 55px !important;
  border: 2px solid var(--border) !important;
  width: 100% !important;
  margin-top: 8px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
}

.phone_input {
  margin-top: 8px !important;
}

.css-13cymwt-control {
  margin-top: 8px !important;
  border: 2px solid var(--border) !important;
  border-radius: 12px !important;
  font-size: 16px;
  padding: 4px;
}

.css-t3ipsp-control {
  margin-top: 8px !important;
  border: 2px solid var(--border) !important;
  border-color: var(--border) !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  padding: 4px !important;
}

.react-tel-input .flag-dropdown {
  padding: 15px 2px !important;
  background-color: var(--gray-200);
  border: 1px solid var(--border) !important;
  border-radius: 14px 0 0 12px !important;
}

.react-tel-input .country-list .flag {
  margin-bottom: 0 !important;
}

.input_div {
  margin-bottom: 24px;
}

.per_info :last-child {
  font-size: 18px;
  font-weight: 500;
}

.per_info :first-child {
  font-size: 14px;
}

.error_text {
  color: var(--red);
  margin: 0;
}

.signup_submit,
.ticket_submit {
  max-width: 360px;
  margin: auto;
}

.signup_submit p,
.ticket_submit p,
.login_submit p {
  text-align: center;
  font-size: 12px;
  margin-bottom: 24px;
}

.textareaField {
  max-width: 100%;
  min-height: 200px;
  resize: vertical;
}

.signup_input,
.ticket_input,
.ads_input {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.radio_input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.radio_input input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0;
}

.radio_input div {
  display: flex;
  align-items: center;
}

.center_text {
  color: var(--primary-color);
  margin-bottom: 0;
  font-weight: 600;
}

.social_icons ul li a,
.social_icons ul li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social_icons ul {
  display: flex;
  gap: 20px;
}

.social_icons ul li a {
  color: white;
}

.social_icons ul li a {
  width: 30px;
  height: 30px;
  background-color: var(--orange-primary);
  border-radius: 100%;
  transition: 0.3s;
}

.social_icons ul li a:hover {
  background-color: var(--primary-white);
  color: var(--primary-black);
}

.radio_input label {
  margin-left: 5px;
}

.btn {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: var(--primary-color);
  color: var(--primary-white);
  box-sizing: border-box;
  border-radius: 100px;
  font-size: 17px;
  cursor: pointer;
  text-decoration: none;
  line-height: 21px;
  text-align: center;
  font-family: "Poppins";
}

.btn_def {
  color: var(--mat-blue);
  background-color: var(--gray-200);
  margin-top: 20px;
  text-align: center;
}

.login_submit p a,
.login_submit span a {
  color: var(--dark-blue);
  text-decoration: none;
}

.login_submit p a:hover,
.login_submit span a:hover {
  text-decoration: underline;
}

.success_container {
  margin: 100px 0;
}

.success_container img {
  width: 180px;
}

.success_container span {
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;
  font-weight: 500;
}

.footer {
  background-color: var(--gray-200);
  border-radius: 32px;
}

.main_container {
  display: grid;
  grid-template-columns: 4fr 1fr;
  padding: 55px 0;
}

.f_sec {
  padding: 20px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.f_sec_1 ul {
  gap: 15px;
}

.f_sec_1 ul li a img {
  width: 16px;
}

.f_sec_1 img {
  width: 130px;
}

.f_sec_2 ul :first-child,
.f_sec_3 ul :first-child {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 500;
}

.f_sec_2 ul li a,
.f_sec_3 ul li a {
  font-size: 16px !important;
  text-decoration: none;
  color: var(--primary-black) !important;
  padding: 5px 0;
  font-weight: 400 !important;
  cursor: pointer;
  display: block;
}

.f_sec_2 ul,
.f_sec_3 ul {
  padding: 0 10px;
}

.f_sec_3 ul li {
  display: flex;
  gap: 10px;
  padding: 8px 0;
  align-items: center;
}

.f_sec_4 {
  cursor: pointer;
}

.f_sec_4 img {
  width: 145px;
}

.f_sec_4 span {
  font-weight: 500;
  margin: 15px 0;
}

.f_sec_4 :last-child {
  margin-top: 10px;
  border-radius: 6px;
}

.Copyright {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  background-color: var(--primary-white);
  padding: 15px 0;
  color: var(--gray-700);
}

.services,
.money_rate {
  margin: 50px auto;
  display: flex;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.taken_service {
  padding: -20px;
}

.visa {
  grid-template-columns: repeat(3, 1fr);
}

.services a {
  border-radius: 12px;
  font-size: 20px;
  font-weight: 500;
  padding: 35px 24px;
  position: relative;
  text-decoration: none;
  color: var(--gray-blue);
}

.money_rate div {
  border-radius: 12px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  text-decoration: none;
  padding: 10px;
  color: var(--gray-blue);
}

.s_sec {
  width: 180px;
}

.s_sec img {
  width: 55px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.s_sec_1,
.s_sec_2,
.s_sec_3,
.s_sec_4,
.download_sug {
  background-size: cover;
  background-position: center;
}

.s_sec a {
  color: var(--gray-blue);
  text-decoration: none;
}

.s_sec:first-child {
  background-image: var(--linear-gradient-orenge);
}

.s_sec:nth-child(2) {
  background-image: var(--linear-gradient-green);
}

.s_sec:nth-child(3) {
  background-image: var(--linear-gradient-pink);
}

.s_sec:nth-child(4) {
  background-image: var(--linear-gradient-blue);
}

.download_sug {
  margin: 70px 0;
  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-items: center;
  align-items: center;
  padding: 30px 0;
  text-align: center;
  background-image: url(./Assets/image-background.png);
}

.d_sec {
  width: 100%;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  justify-items: center;
  align-items: center;
}

.w_img img {
  width: 200px;
}

.s_btn {
  gap: 10px;
}

.phone_img img {
  width: 185px;
  margin-bottom: -35px;
}

.tcs_main {
  margin: 70px 0;
}

.tcs_box,
.service_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  margin: 35px 0;
}

.tsc_item {
  border: 1px solid var(--border);
  padding: 12px;
  border-radius: 14px;
  object-fit: cover;
  text-align: center;
  width: 190px;
}

.tsc_item img {
  width: 100%;
  height: 115px;
  border-radius: 14px;
}

.term_info {
  display: block;
}

.tsc_item span {
  display: block;
  cursor: pointer;
  text-decoration: none;
  color: var(--primary-black);
}

.tsc_item span:hover {
  text-decoration: underline;
}

.dtl {
  color: var(--primary-color);
  font-weight: 600;
}

.embassy {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.VT_main,
.embassy_main {
  margin: 70px 0;
}

.ed_container {
  background-color: var(--pink-primary);
  padding: 40px 0;
  margin: 70px 0;
}

.ed_main {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 35px 0;
  gap: 20px;
  justify-content: space-between;
}

.ed_item {
  display: flex;
  background-color: var(--primary-white);
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  gap: 20px;
}

.ed_item div,
.basic_info ul,
.bloger_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ed_item div {
  width: 100%;
}

.ed_item div :first-child {
  font-size: 23px;
}

.ed_item div span {
  font-weight: 500;
  color: var(--mat-blue);
}

.ed_item img {
  width: 235px;
}

.def_date {
  display: block;
  padding: 10px;
  background-color: var(--pink);
  width: 115px;
  border-radius: 12px;
}

.other_service {
  display: flex;
  justify-content: space-between;
  margin: 70px 0;
  gap: 25px;
}

.other_service div {
  object-fit: cover;
  position: relative;
  cursor: pointer;
}

.other_service div img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.other_text {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--primary-black);
  color: var(--primary-white);
  padding: 25px;
  text-align: center;
  width: 100%;
  right: 0;
  box-sizing: border-box;
  border-radius: 0px 0px 12px 12px;
  transform: scaleY(0);
  transform-origin: bottom;
  transition-duration: 0.3s;
  font-size: 28px;
  font-weight: 500;
  text-decoration: none;
}

.other_service div:hover .other_text {
  transform: scaleY(1);
}

.def_text {
  background-color: var(--yellow);
  color: var(--mat-blue);
}

.main_s_box {
  margin: 70px 0;
}

.button_div {
  display: flex;
  justify-content: center;
}

.outline_btn {
  padding: 10px 20px;
  border-radius: 25px;
  font-weight: 500 !important;
  border: 1px solid var(--primary-black);
  font-size: 17px;
  cursor: pointer;
  font-family: "Poppins";
}

.req_container {
  max-width: 950px;
  margin: 50px auto;
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 45px;
}

.visa {
  /* padding: 250px; */
  margin-top: 50px !important;
  box-sizing: border-box;
  /* height: 50vh; */
  margin: auto;
}

.selection {
  display: flex;
  justify-content: space-evenly;
}

.select {
  width: 300px;
  padding: 10px;
  border: 1px solid var(--primary-color);
  border-radius: 12px;
}

.select option {
  background-color: var(--primary-white);
  padding: 10px;
  border: 1px solid var(--primary-color);
}

.visa_container {
  margin: 30px auto;
  max-width: 800px;
  margin: auto;
}

.back_icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.req_intro {
  display: flex;
  align-items: center;
  gap: 10px;
}

.req_subox {
  padding: 0px 50px;
  margin-bottom: 30px;
}

.req_box h2 {
  background-color: var(--primary-black);
  padding: 10px;
  border-radius: 12px;
  color: var(--primary-white);
  text-align: center;
}

.c_text,
.free_line {
  font-size: 18px;
  font-weight: 400;
  padding: 15px 10px;
  /* text-align: center; */
  display: block;
}

.c_text,
.free_line span {
  padding: 6px 15px;
  border-radius: 8px;
}

.c_text span,
.free_line {
  text-align: center;
  font-weight: 700;
  text-align: center;
  font-weight: 700;
  padding: 6px 15px;
  border-radius: 8px;
}

.c_text {
  text-align: center;
}

.c_text span {
  color: var(--purple-primary);
  background-color: var(--purple-background);
}

.free_line {
  color: var(--gray-500);
  text-align: left;
  background-color: var(--gray-200);
  border-radius: 12px;
}

.dlt_line {
  padding: 20px;
  color: var(--gray-500);
  text-align: left;
  background-color: var(--gray-200);
  border-radius: 12px;
}

.dlt {
  color: var(--primary-color);
}

.free_line span {
  background-color: var(--orange-background);
  color: var(--orange-primary);
}

.requerments li {
  display: flex;
  gap: 20px;
  background-color: var(--gray-200);
  margin: 20px 0;
  border-radius: 12px;
}

.requerments li :first-child {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 12px;
  background-color: var(--primary-white);
  border: 1px solid var(--gray-200);
}

.requerments li :last-child {
  padding: 10px;
  border-radius: 10px;
}

.req_main {
  margin-top: 30px;
}

.req_submit {
  max-width: 360px;
  margin: auto;
  text-align: center;
}

.req_submit p {
  font-size: 16px;
  color: var(--gray-600);
}

.suc_submit span {
  padding: 15px;
  color: var(--gray-600);
  display: block;
}

.suc_submit div {
  width: 250px;
  margin: 20px auto;
}

.p_main_sec {
  display: grid;
  grid-template-columns: 3fr minmax(0, 7fr);
  overflow: hidden;
  gap: 10px;
}

.p_main_sec {
  margin: 50px 0;
}

.avatar {
  width: 100px;
  margin: auto;
  margin-bottom: -45px;
}

.avatar img {
  width: 100%;
}

.camera {
  width: 30px !important;
  border-radius: 100%;
  padding: 6px;
  background-color: var(--dark-blue);
  position: relative;
  bottom: 47px;
  cursor: pointer;
  right: -63px;
}

.user_avatar {
  position: relative;
  border-radius: 50%;
}

.info_sec,
.profile_sec,
.taken_services,
.contect_container {
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 20px;
}

.contect_container ul li {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  align-items: center;
}

.user_info {
  text-align: center;
  border-bottom: 1px solid var(--border);
}

.user_info h3 {
  margin-bottom: 0;
}

.user_info div,
.user_info div p {
  display: flex;
  align-items: center;
}

.user_info div {
  justify-content: space-between;
}

.user_info div p {
  font-size: 14px;
  gap: 6px;
  color: var(--gray-700);
}

.manage_acc ul li a {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 12px;
  text-decoration: none;
  color: var(--gray-700);
}

.manage_acc ul li:hover {
  background-color: var(--gray-200);
}

.manage_acc ul li {
  border-radius: 12px;
}

.sign_out {
  cursor: pointer;
}

.sign_out,
.sign_out button,
.down_btn button {
  display: flex;
  justify-content: center;
}

.sign_out span {
  gap: 10px;
}

.sign_out button {
  align-items: center;
  padding: 10px;
  width: 100%;
  border: none;
  gap: 10px;
  cursor: pointer;
  color: var(--red);
  background-color: var(--primary-white);
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}

.down_btn {
  margin-top: 25px;
  background-color: var(--gray-200) !important;
  display: none !important;
}

.down_btn button {
  gap: 10px;
  border: none;
  background-color: var(--gray-200);
  font-weight: 500;
  font-family: "Poppins";
}

.info_sec h3 {
  margin: 0;
  color: var(--dark-blue);
}

.basic_details {
  padding: 0 20px;
}

.basic_details button {
  background: var(--primary-black);
  border: none;
  color: white;
  font-size: 17px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 25px;
  gap: 10px;
  font-weight: 400;
  cursor: pointer;
  font-family: "Poppins";
}

.basic_info ul li :first-child {
  color: var(--gray-600);
}

.basic_info ul li {
  max-width: 600px;
  display: grid;
  grid-template-columns: 3fr 7fr;
  padding: 10px 0;
  border-bottom: 1px solid var(--border);
}

.basic_info ul {
  height: 375px;
  padding: 0px 20px;
}

.req_intro_back h3 {
  color: var(--primary-color);
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 30px 0;
}

.table tbody tr {
  cursor: pointer;
}

.table tbody a {
  display: block;
  width: 100%;
  text-decoration: none;
}

.table th,
.table td {
  text-align: center;
  padding: 15px 0;
}

.table th {
  background-color: var(--gray-200);
  margin-bottom: 20px;
  font-weight: 500;
  color: var(--gray-700);
}

.ticket_container,
.course_contaner,
.day_container,
.Exc_container,
.NcPat_container,
.quiz_container,
.capitolo_container,
.add_container {
  max-width: 980px;
  margin: 30px auto;
}

.taken_services {
  max-width: 980px;
}

.valid_date {
  margin-top: 15px;
}

.table td {
  border-bottom: 1px solid var(--border);
  color: var(--gray-700);
}

.status span {
  padding: 5px 10px;
  background-color: var(--primary-color);
  max-width: 100px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-family: "Poppins";
  border: none;
  border-radius: 12px;
  color: var(--primary-white);
  margin: auto;
}

.s_complete span {
  background-color: var(--primary-color);
}

.s_fail span {
  background-color: var(--red);
}

.s_pending span {
  background-color: var(--orange-primary);
  color: var(--primary-black);
}

.s_inactive span {
  background-color: var(--gray-blue);
}

.date_sec {
  padding: 15px 10px;
  background-color: var(--orange-background);
  border-radius: 12px;
  text-align: center;
}

.date_sec p,
.date_sec h2 {
  margin: 0;
}

.date_sec p {
  margin-bottom: 10px;
}

.details_main {
  margin: 30px auto !important;
}

.ticket_main,
.add_main {
  margin: 30px auto;
  max-width: 740px;
}

.couser_main,
.details_main,
.day_main,
.exc_main,
.NcPat_main,
.capitolo_main,
.quiz_page_main,
.blog_d_container {
  max-width: 860px;
  margin: auto;
}

.c_title {
  text-align: center;
  background-color: var(--sky-blue);
  border-radius: 10px;
  padding: 10px;
}

.video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.course_demo_info p {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
}

.course_days,
.Quiz_item {
  max-width: 650px;
  margin: 50px auto;
  /* padding: 15px; */
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 10px;
}

.course_days li a,
.Quiz_item li a {
  padding: 15px 30px;
  background-color: var(--mat-blue);
  border-radius: 10px;
  font-weight: 500;
  color: var(--primary-white);
  text-decoration: none;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.course_days li span,
.Quiz_item li span {
  padding: 15px 30px;
  background-color: var(--disabl_blue);
  border-radius: 10px;
  font-weight: 500;
  color: var(--primary-white);
  text-decoration: none;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

ul.course_days li {
  display: flex;
  height: 100%;
}

.day_container {
  margin: 30px auto;
}

.day_box {
  max-width: 350px;
  margin: auto;
  margin-bottom: 80px;
}

.amico_img img {
  width: 100%;
}

.amico_img {
  max-width: 280px !important;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 40px;
}

.exc_btn {
  display: block;
  text-align: center;
  max-width: 350px;
  margin: auto;
}

.exe_box {
  margin-top: 100px;
  margin-bottom: 70px;
}

.exe_box ul {
  max-width: 430px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
}

.exc_item span {
  font-weight: 500;
}

.exc_item :first-child {
  padding: 10px 15px;
  background-color: var(--primary-black);
  color: var(--primary-white);
  border-radius: 50px;
}

.exc_item input {
  font-weight: 500 !important;
  padding: 12px;
  border-radius: 30px;
  text-align: center;
  border: none;
  /* max-width: 100px; */
  font-size: 14px;
  font-family: "Poppins";
  background-color: var(--gray-200);
}

.ans_area span {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  color: var(--primary-color);
}

.right {
  border: 1px solid var(--primary-color) !important;
}

.worng {
  border: 1px solid var(--red) !important;
}

.NcPat_sub {
  max-width: 700px;
  margin: 40px auto;
}

.NcPat_box {
  gap: 20px;
  justify-content: space-between;
}

.NcPat_box a {
  gap: 15px;
  border-radius: 12px;
  text-decoration: none;
}

.green {
  display: block;
  margin: 20px 0;
  background-color: var(--mid-green) !important;
}

.pink {
  display: block;
  margin: 20px 0;
  background-color: var(--lite-pink);
}

.nc_item {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}

.nc_text {
  font-size: 24px;
  font-weight: 500;
  text-decoration: none;
  color: var(--primary-black);
  display: block;
}

/* .pdf-container {
  width: 100%;
  height: 100vh;
  margin: 40px 0;
  overflow: hidden;
}

.pdf {
  width: 100%;
  height: 100%;
} */

/* .pdf-container {
  width: 100%;
  height: calc(100vh - 40px);
  margin: 40px 0;
  overflow-y: auto;
  border: 1px solid var(--border);
  border-radius: 12px;
}

.pdf {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

canvas.react-pdf__Page__canvas {
  margin: auto;
}

.pdf ::-webkit-scrollbar-track {
  border-radius: 5px;
} */

.pdf-container {
  width: 100%;
  height: calc(100vh - 40px);
  margin: 40px 0;
  overflow-y: auto;
  border: 1px solid var(--border);
  border-radius: 12px;
}

.pdf-container::-webkit-scrollbar {
  width: 10px;
}

.pdf-container::-webkit-scrollbar-thumb {
  border-radius: 5px 5px 5px 5px;
}

.pdf-container::-webkit-scrollbar-track {
  border-radius: 0px 13px 13px 0px;
}

.advertis_container {
  width: 100%;
  margin: 40px 0;
}

.advertis_container div {
  margin: 40px 0;
}

.center_title,
.browse_more {
  text-align: center;
}

.upload_img {
  border: 1px solid var(--primary-color);
  border-radius: 12px;
  margin: 30px 0;
  padding: 20px;
  text-align: center;
}

.upload_img img {
  width: 120px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
}

.blog_header {
  display: grid;
  gap: 40px;
  grid-template-columns: 4fr 3fr;
}

.blog_box_content {
  border: 1px solid var(--border);
  padding: 15px;
  border-radius: 12px;
  width: 330px;
}

.blog_header_img {
  width: 100%;
  overflow: hidden;
}

.blog_header_img img {
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  height: 100%;
}

.blog_img {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.blog_img img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.blog_container {
  margin: 60px 0;
}

.upload_by {
  display: flex;
  gap: 15px;
}

.upload_by img {
  width: 50px;
}

.blog_header_info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.article_info {
  margin-top: 20px;
}

.date,
.upload_date {
  display: flex;
  gap: 15px;
  align-items: center;
}

.dot {
  width: 5px;
  height: 5px;
  background-color: var(--gray-600);
  border-radius: 100%;
}

.article_box {
  margin: 50px 0;
}

.upload_date {
  justify-content: center;
}

.blog_d_container {
  margin: 50px auto;
}

.blog_d_img {
  margin-top: 50px;
}

.date span,
.upload_date span {
  color: var(--gray-400);
}

.info p {
  color: var(--gray-400);
}

.info a {
  font-size: 18px;
  text-decoration: none;
  font-weight: 600;
  color: var(--primary-blackf);
  cursor: pointer;
}

.title_user {
  max-width: 535px;
  margin: auto;
}

.title_user h3 {
  text-align: center;
  font-size: 24px;
}

.upload_by_d {
  justify-content: center;
}

.blog_box,
.article_box {
  display: flex;
  row-gap: 60px;
  column-gap: 35px;
  flex-wrap: wrap;
  justify-content: center;
}

.bloger_name {
  font-size: 16px;
}

.profession {
  font-size: 14px;
  color: var(--gray-400);
}

.blog_d_img img {
  width: 100%;
}

.blog_d_container p {
  color: var(--gray-400);
}

.input_error {
  border: 2px solid var(--red) !important;
}

.bar_icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.paypal_express{
  width: 300px;
  max-width: 300px !important;
}

.modal_form {
  margin-bottom: 10px;
}

.close_modal {
  color: var(--primary-black);
  background-color: var(--gray-200);
}

.ReactModal__Content.ReactModal__Content--after-open {
  max-height: 80vh;
  border-radius: 12px !important;
}

.per_box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 600px;
  width: 700px;
  margin-bottom: 20px;
}

.term_box {
  max-width: 600px;
}

.sub_term {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.checkbox > input[type="checkbox"] {
  margin-top: 20px;
  width: 20px !important;
  height: 20px !important;
  border-radius: 2px;
  border: 1px solid #7a7a9d;
  box-sizing: border-box;
}

.checkbox > input[type="checkbox"]:checked {
  background-color: green;
}

.modal_def {
  margin-top: 20px;
}

.term_coler {
  color: var(--primary-color);
}

.disabled {
  background-color: var(--mid-green);
}

.disabled_t {
  pointer-events: none;
}

.NcPat_container {
  padding: 0px 20px;
}

.qTopSec {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.exam_board {
  display: flex;
  align-items: flex-start;
  width: 270px;
}

.exam_board img {
  max-width: 100px;
  box-sizing: border-box;
}

.quiz_box {
  background-color: var(--mid-green);
  padding: 20px;
  border-radius: 12px;
}

.q_left_text {
  font-size: 12px;
  color: var(--dark-blue);
  display: inline-block;
  line-height: 13px;
  font-weight: 400;
}

.qT_img {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.qT_img img {
  max-width: 100px;
}

.q_left_text :first-child {
  font-size: 12px;
  font-weight: 500;
}

.domande_box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.page_serial {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 2px;
  margin: 5px 0;
  text-align: center;
  font-size: 25px;
}

.quiz_serial {
  display: grid;
  grid-template-columns: repeat(30, 1fr);
  gap: 2px;
}

.quiz_serial span {
  width: 100%;
  height: 20px;
  text-align: center;
  background-color: var(--primary-white);
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--gray-600);
  margin-top: 10px;
  cursor: pointer;
}

.dom_sec,
.page_serial span {
  width: 100%;
  background-color: var(--primary-white);
  border-radius: 6px;
  color: var(--gray-600);
  padding: 0 5px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
}

.dom_sec_serial {
  text-align: center;
}

.dom_sec_active {
  color: var(--blue);
}

.quiz_area {
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: 10px;
}

.quiz_area {
  margin-top: 20px;
}

.left_area {
  background-color: var(--primary-white);
  border-radius: 6px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left_area .img {
  max-width: 200px;
  max-height: 200px;
}

.left_area .img img {
  width: auto;
  max-width: 200px;
  max-height: 200px;
  box-sizing: border-box;
  margin: auto;
}

.right_area {
  display: grid;
  gap: 10px;
}

.right_area_uper,
.right_area_bottom {
  background-color: var(--primary-white);
  padding: 10px;
  border-radius: 6px;
}

.quiz_info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.serial_quiz {
  width: 30px;
  font-size: 20px;
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  border: 2px solid var(--border);
}

.question {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 12px;
  border-radius: 6px;
}

.question span {
  position: relative;
  cursor: pointer;
}

.question_box {
  display: flex;
  align-items: center;
  height: 200px;
  overflow-x: auto;
}

.indicator {
  position: absolute !important;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid var(--border);
  padding: 10px;
  border-radius: 10px;
  background-color: var(--primary-white);
}

.seaker_icon {
  cursor: pointer;
  margin: 15px 10px;
  color: var(--primary-color);
}

.question p {
  margin: 0;
  padding: 10px;
}

.right_area_bottom {
  display: flex;
  gap: 25px;
  justify-content: center;
}

.right_area_bottom button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Poppins";
  background-color: var(--primary-white);
  border: none;
}

.right_area_bottom button img {
  width: 50px;
  cursor: pointer;
}

.right_area_bottom button {
  text-align: center;
}

.submit_area {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 10px;
}

.tempo_box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.tempo {
  border-radius: 6px;
  background-color: var(--primary-white);
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 10px;
}

.tempo :first-child {
  display: block;
  width: 145px;
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-blue);
  font-weight: 600;
}

.tempo :last-child {
  font-size: 35px;
  text-align: center;
  font-weight: 600;
}

.Scheda_box div,
.sb_mobile div {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.Scheda_box div :first-child,
.sb_mobile div :first-child {
  font-size: 14px;
  color: var(--dark-blue);
  font-weight: 500;
}

.Scheda_box div :last-child,
.sb_mobile div :last-child {
  font-size: 18px;
  color: var(--dark-blue);
  font-weight: 500;
  display: block;
  text-align: end;
  border-radius: 6px;
  background-color: var(--primary-white);
  padding-right: 15px;
}

.Scheda_box,
.sb_mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 300px;
}

.sb_mobile {
  display: none;
}

.click_box {
  font-size: 14px;
  display: flex !important;
  flex-direction: column;
  color: var(--dark-blue);
  font-weight: 500;
  width: 100%;
  justify-content: space-around;
}

.clk_box {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 20px;
  justify-content: center;
}

.click_icons {
  color: var(--primary-white);
  font-size: 25px;
  border: 3px solid var(--primary-white);
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

.uper_scheda {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.sub_click_box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  align-items: center;
}

.chiudi_btn {
  background-color: var(--primary-white);
  border: none;
  padding: 5px;
  border-radius: 6px;
  width: 100%;
  font-weight: 500;
  font-family: "Poppins";
  cursor: pointer;
}

.answer_area {
  margin-top: 20px;
  background: var(--primary-white);
  padding: 10px;
}

.ques {
  display: grid;
  grid-template-columns: 2fr 8fr 1fr 1fr;
}

.ques_title,
.ques_result span {
  width: 100%;
  display: block;
  border-bottom: 1px solid var(--border);
  font-weight: 600;
}

.ques_index,
.ques_title {
  background-color: var(--red-50);
}

.ques_index {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.ques_index span {
  font-size: 20px;
  font-weight: 500;
  color: var(--gray-800);
  padding-left: 10px;
}

.ques_index img {
  max-width: 40px;
  box-sizing: border-box;
}

.ques_item {
  border: 1px solid var(--border);
}

.the_ques {
  padding: 10px;
  display: block;
}

.ques_result span,
.ques_result {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ques_result div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.embassy_container {
  width: 100%;
  height: calc(100vh - 30px);
  margin: 30px 0;
}

.embassy_embed {
  width: 100%;
  height: 100%;
}

.add_item,
.tour_item {
  border: 1px solid var(--border);
  border-radius: 12px;
  padding: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.add_item p {
  margin: 0;
}

.user_switch,
.user_switch span {
  background-color: var(--primary-color);
  display: flex;
  gap: 10px;
  margin: 30px auto;
  border-radius: 10px;
  padding: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.user_switch span {
  margin: auto;
  display: block;
  cursor: pointer;
  color: var(--primary-white);
}

.trip_active {
  background-color: var(--primary-white) !important;
  color: var(--primary-black) !important;
  text-align: center;
}

.user_switch a {
  color: var(--primary-white);
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
}

.add_user {
  background-color: var(--primary-white) !important;
  color: var(--primary-color) !important;
}

.suc_btn {
  margin: 30px 0;
}

.add_item img,
.tour_item img {
  max-width: 200px;
  border-radius: 12px;
}

.tour_item {
  margin: 30px;
  color: var(--primary-black);
  text-decoration: none;
}

.tour_item > div:hover {
  text-decoration: underline;
}

.tour_Title {
  font-size: 18px;
  font-weight: 500;
}

.no_add {
  text-align: center;
}

.upload_img p {
  margin: 0;
}

.right_answer {
  background-color: var(--primary-color) !important;
  color: var(--primary-white) !important;
}

.wrong_answer {
  background-color: var(--red) !important;
  color: var(--primary-white) !important;
}

.blue {
  color: var(--blue);
}

.green_ans {
  color: var(--primary-color);
}

.red_ans {
  color: var(--red);
}

.exam_modal h2 {
  color: var(--red);
  font-size: 30px;
  margin: 0;
}

.exam_modal {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.exam_modal > .btn:last-child {
  background-color: var(--orange-primary) !important;
}

.exam_modal > .purple {
  background-color: #8050ff !important;
}

.exam_modal span {
  margin: 0;
  font-size: 18px;
  color: var(--red);
  font-weight: 500;
}

.the_icon {
  font-size: 80px;
  text-align: center;
  width: 100%;
  margin: 20px 0;
}

.exam_modal button {
  margin-top: 20px;
}

.course_days input[type="checkbox"] {
  width: 25px;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  margin-right: 10px;
}

.disabled-link {
  color: #ccc;
  pointer-events: none;
  text-decoration: none;
  cursor: not-allowed;
}

.profile_pic {
  width: 500px;
}

.crop_button button {
  margin: 10px 0;
}

.croped_img img {
  width: 300px;
  height: 300px;
  margin: auto;
  display: block;
}

.croping_img {
  max-width: 500px;
}

.cropper-crop {
  max-width: 500px !important;
}

.status span {
  cursor: pointer;
}

.carousel {
  margin-top: 30px;
  max-height: 400px;
}

.rate {
  font-size: 20px;
  font-weight: 500;
  /* color: var(--primary-white); */
}

.sourse_rate {
  display: block;
  text-align: right;
  margin-top: 10px;
  padding-right: 20px;
}

.translate-popover {
  position: absolute;
  background-color: #f9f9f9;
  color: #000;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.translate-popover-arrow {
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #f9f9f9;
}

.contect_us {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 40px;
  justify-content: space-around;
  margin: 30px auto;
}

.left_contect h1,
.left_contect p {
  margin: 0;
}

.contect_info {
  padding: 20px;
  border-radius: 12px;
  background-color: var(--scecoundry-color);
  color: var(--primary-white);
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  position: relative;
  overflow: hidden;
}

.curcle,
.curcle_big {
  position: absolute;
  border-radius: 100%;
  display: block;
}

.curcle {
  width: 138px;
  bottom: 80px;
  right: 80px;
  height: 138px;
  background-color: var(--transparent-circle);
}

.curcle_big {
  width: 250px;
  bottom: -60px;
  right: -60px;
  height: 250px;
  background-color: var(--transparent-circle);
}

.service_details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.service_details div span {
  display: block;
}

.service_details div {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  border-radius: 12px;
  background-color: var(--gray-200);
}

.details_pill span {
  max-width: 100% !important;
}

.service_title {
  font-weight: 500;
  font-size: 18px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 30px 0;
}

.table tbody tr {
  cursor: pointer;
}

.table tbody a {
  display: block;
  width: 100%;
  text-decoration: none;
}

.table th,
.table td {
  text-align: center;
  padding: 15px 0;
}

.table th {
  background-color: var(--gray-200);
  margin-bottom: 20px;
  font-weight: 500;
  color: var(--gray-700);
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.toggle-button input {
  display: none;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9e9e9e;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider.active {
  background-color: #4e944f;
}

.slider:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease;
}

.slider.active:before {
  transform: translateX(25px);
}

.tempo_btn {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.finish_btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.repeat_icon {
  color: white;
  border: 2px solid white;
  padding: 2px 4px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 6px;
}

.n_download {
  display: flex;
  gap: 10px;
}

.req_document {
  margin: 0;
  padding-right: 10px;
}

.doc_upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app_down h2 {
  margin: 5px;
}

.down_link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.down_icon {
  font-size: 20px;
}

.req_document {
  margin: 15px 0;
}

.disabaled {
  background-color: var(--primary-color);
  opacity: 0.7;
}

.yellow_btn {
  background-color: var(--yellow);
  color: purple;
}

.ticket_container {
  position: relative;
  z-index: 1;
}

.comming_soon {
  font-size: 20px;
  color: var(--red);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  z-index: 9999;
}

/* .tour_img{
  height: 80% !important;
} */

.visa_ticket_travel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.visa_ticket_travel .tour-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.visa_ticket_travel .tour-item .tour_img_container {
  border-radius: 12px;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
}

.visa_ticket_travel .tour-item span {
  display: block;
  padding: 10px;
}

.visa_services {
  margin-top: 20px;
}

.visa_rs {
  margin-top: 30px;
}

.user_on_nav {
  display: flex;
  gap: 10px;
  align-items: center;
}

.valid_date_nav > .date_sec {
  padding: 5px 10px;
  cursor: pointer;
}

.valid_date_nav > .date_sec > p {
  font-size: 8px;
  margin-bottom: 0;
}

.valid_date_nav > .date_sec > h2 {
  font-size: 14px;
}

.notif {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px;
  padding-top: 0;
}

.notif > .infinite-scroll-component__outerdiv > .infinite-scroll-component {
  height: 350px;
}

.notif_drop {
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 10px;
  background-color: var(--primary-white);
  box-shadow: 6px 6px 15px var(--primary-shadow);
  transform: scaleY(0);
  transform-origin: top;
  transition-duration: 0.4s;
  text-decoration: none;
  width: 350px;
  right: 0px;
}

.notif_drop > p {
  font-size: 12px;
}

.notification {
  font-size: 20px;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
}

.notification:hover {
  background-color: var(--gray-300);
}

.notif_main:hover .notif_drop {
  transform: scaleY(1);
}

.notif_img {
  display: flex;
  align-items: center;
}

.notif_img .img {
  width: 25px;
  height: 25px;
}

.notif_box {
  display: flex;
  gap: 15px;
}

.notic_title {
  margin: 0;
}

.notif_discription {
  margin: 0;
}

h3.notic_title {
  font-size: 16px;
}

p.notif_discription {
  font-size: 12px;
  line-height: 16px;
}

.notif_con {
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  text-decoration: none;
  color: #242424;
  margin: 5px 0;
}

.notif_con:hover {
  background-color: var(--sky-blue);
}

.un_read {
  background-color: var(--sky-blue);
}

.chat_container {
  max-width: 700px;
  margin: 30px auto;
}

.chat {
  display: flex;
  gap: 15px;
  margin: 20px 0;
}

.chat_box {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.chat_box_author {
  align-items: start !important;
}

.chat_info {
  background-color: var(--sky-blue);
  padding: 15px;
  border-radius: 12px;
  max-width: 70%;
}

.chat_info > span {
  font-size: 12px;
  color: var(--gray-500);
}

.chat_person {
  color: var(--gray-600);
}

.user_time {
  display: flex;
  justify-content: end;
}

.chat_info p {
  font-size: 14px;
  margin: 0;
}

.chat_user {
  justify-content: flex-end;
}

.chat_topic {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--yellow);
  padding: 10px;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
}

.help_container {
  max-width: 720px;
  margin: 30px auto;
}

.help_ticket {
  display: flex;
  gap: 15px;
  margin: 20px 0;
}

.help_ticket > .img {
  width: 50px;
  height: 50px;
}

.help_ticket > .img > img {
  width: 50px;
  height: 50px;
}

.help_info h4,
.help_info p {
  margin: 0;
}

.help_info {
  background-color: var(--gray-300);
  padding: 15px;
  border-radius: 12px;
}

.ticket_modal {
  max-width: 700px;
}

.help_submit > .input_div {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 100px;
  gap: 10px;
  margin-top: 50px;
}

.help_submit > .input_div > input {
  width: 100%;
  margin-top: 0;
}

.notif_title {
  display: flex;
  gap: 5px;
  align-items: center;
}

.notif_title > h3 {
  margin: 0;
}

.notif_title > img {
  cursor: pointer;
}

.notif_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.notif_read {
  font-size: 12px;
  color: var(--primary-color);
  cursor: pointer;
}

.notif_read:hover {
  text-decoration: underline;
}

.zoid-outlet{
  width: 350px;
  /* max-width: 350px !important; */
  width: 100% !important;
}